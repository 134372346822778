import React, { useState, useRef, useEffect } from 'react';
import './ContactUs.css';
import OwlCarousel from 'react-owl-carousel';
import 'animate.css';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Footer from './Footer';


function ContactUs() {
    const form = useRef();
    const [loading, setLoading] = useState(true);
    const Send = (e) => {
        e.preventDefault();
        emailjs.sendForm('thivja', 'template_prqeoz7', form.current, 'I4HT5OQAfg_uTlrFd')
            .then(
                (result) => {
                    console.log('SUCCESS!', result.text);
                    toast.success('The message is successfully sent', {
                        position: "top-center"
                    });
                    form.current.reset();
                },

                (error) => {
                    console.error('FAILED...', error.text);
                    toast.error('Failed to send the message', {
                        position: "top-center"
                    });
                }

            );

    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500); // simulate loading delay
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-border text-green" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
    return (
        <div>
            <Header />

            {/* Contact page start */}
            <div className="contact-page">
                <div className="contact-header">
                    <h1 className="contact-title">Lets's Talk</h1>
                    <p className="contact-subtitle">We're here to help.</p>
                </div>
                <div className="contact-container">
                    <div className="contact-form-wrapper">
                        <form className="contact-form" ref={form} onSubmit={Send}>
                            <div className="form-group">
                                <label htmlFor="name">Full name</label>
                                <input type="text" id="name" name="from_name" placeholder="Enter your full name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">From Email</label>
                                <input type="email" id="email" name="from_email" placeholder="Enter your address" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="contact">Contact number</label>
                                <input type="tel" id="contact" name="contact" placeholder="Enter your contact number" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Describe your message</label>
                                <textarea id="message" name="message" placeholder="Enter here" required></textarea>
                            </div>
                            <button type="submit" className="submit-button">Submit</button>
                        </form>
                    </div>
                    <div className="contact-info">
                        <h3>REACH US</h3>
                        <p>Feel free to reach out, and our team will respond promptly to discuss how we can support your goals.</p>
                        <a href="mailto:thepineappleai.com">
                            <i className="fas fa-envelope"></i>global.hr.pineappleai@gmail.com
                        </a>
                        <p>
                            <i className="fas fa-phone"></i>077-8142648
                        </p>
                    </div>
                </div>
                <ToastContainer />
            </div>
            {/* Contact page end */}


            {/* Footer Start */}
            <Footer />
            {/* Footer End */}
            {/* Copyright Start */}
            <div className="container-fluid copyright py-4">
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-md-6 text-center text-md-start mb-md-0">
                            <span className="text-body"><a href className="border-bottom text-white"><i className="fas fa-copyright text-light me-2" />PINEAPPLEAI.CLOUD </a>, All right reserved.</span>
                        </div>
                        <div className="col-md-6 text-center text-md-end text-body">
                            Designed By <a className="border-bottom text-white" href>PineappleAI</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Copyright End */}
            {/* Back to Top */}
            <a href="#" className="btn btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up" /></a>
            {/* JavaScript Libraries */}
            {/* Template Javascript */}
        </div>

    )
}

export default ContactUs;

