import React from "react";
import { NavLink } from 'react-router-dom';

function Header() {
    return (
        <>
            {/* Topbar Start */}
            <div className="container-fluid topbar px-0 d-none d-lg-block">
                <div className="container px-0">
                    <div className="row gx-0 align-items-center" style={{ height: '45px' }}>
                        <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                            <div className="d-flex flex-wrap">
                                <a href="/" className="text-white text-decoration-none me-4">
                                    <i className="fas fa-map-marker-alt text-green me-2" />Find A Location
                                </a>
                                <a href="/" className="text-white text-decoration-none me-4">
                                    <i className="fas fa-phone-alt text-green me-2" /> 077-8142648
                                </a>
                                <a href="/" className="text-white text-decoration-none me-0">
                                    <i className="fas fa-envelope text-green me-2" />thepineappleai@gmail.com
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center text-lg-end">
                            <div className="d-flex align-items-center justify-content-end">
                                <a href="https://web.facebook.com/profile.php?id=100094538022740" className="btn btn-primary btn-square rounded-circle nav-fill me-3">
                                    <i className="fab fa-facebook-f text-green" />
                                </a>
                                <a href="https://www.youtube.com/@Infra-y4g" target="_blank" className="btn btn-primary btn-square rounded-circle nav-fill me-3">
                                    <i className="fab fa-youtube text-green" />
                                </a>
                                <a href="/" className="btn btn-primary btn-square rounded-circle nav-fill me-3">
                                    <i className="fab fa-instagram text-green" />
                                </a>
                                <a href="https://www.linkedin.com/company/pineappleaicloud/" target="_blank" className="btn btn-primary btn-square rounded-circle nav-fill me-0">
                                    <i className="fab fa-linkedin-in text-green" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Topbar End */}

            {/* Navbar & Hero Start */}
            <div className="container-fluid sticky-top px-0">
                <div className="position-absolute bs-green" style={{ left: 0, top: 0, width: '100%', height: '50%' }}></div>
                <div className="container px-0">
                    <nav className="navbar navbar-expand-lg navbar-dark bg-white py-3 px-4">
                        <a href="/" className="navbar-brand p-0">
                            <h1 className="text-green m-0"><img src="img/X2.png" alt="Logo" /></h1>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <img src="img\menu_50px.png" alt="Logo" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto py-0">
                                <NavLink to="/home" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>Home</NavLink>
                                <NavLink to="/aboutus" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>About</NavLink>
                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                    <div className="dropdown-menu m-0">
                                        <a href="/projects" className="dropdown-item active-dropdown-item">UpComing Projects</a>
                                        <a href="/team" className="dropdown-item active-dropdown-item">Our Team</a>
                                    </div>
                                </div>

                                <NavLink to="/contact" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>Contact</NavLink>
                            </div>
                            <div className="d-flex align-items-center flex-nowrap pt-xl-0">
                                {/* <button className="btn btn-dark btn-md-square mx-2" data-bs-toggle="modal" data-bs-target="#searchModal">
                                    <i className="fas fa-search" />
                                </button> */}
                                <a href="/career" className="btn btn-dark rounded-pill text-white py-2 px-4 ms-2 flex-wrap flex-sm-shrink-0">Careers</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            {/* Navbar & Hero End */}
        </>
    );
}

export default Header;
